import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.css']
})
export class NoticiasComponent implements OnInit {


  noticias = [
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DCWm2GBv5Jh/', img:'../../../assets/noticias/noticia55.webp', titulo: ' Día Mundial de la Diabetes.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DCUr5OvBs8t/', img:'../../../assets/noticias/noticia54.webp', titulo: 'CALILAB 2024: El 6, 7 y 8 de Noviembre se realizó el congreso bioquimico más importante del país y latinoamerica.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DCRkswsCnpk/', img:'../../../assets/noticias/noticia53.webp', titulo: '¿Sabías que el ayuno es clave para obtener resultados precisos en tus análisis de laboratorio? '},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DCJ4Z8GtjuV/', img:'../../../assets/noticias/noticia52.webp', titulo: 'Día de la primera transfusión de sangre.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DCHxCrVCeqj/', img:'../../../assets/noticias/noticia51.webp', titulo: 'Dengue: Síntomas.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DB_hSg2tFBj/', img:'../../../assets/noticias/noticia50.webp', titulo: 'En Laboratorio Romano atendemos por Prepagas, Obras Sociales y Particulares.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DBuj1Vkppm8/', img:'../../../assets/noticias/noticia49.webp', titulo: 'Consejos para después de una extracción de sangre.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DBjIM2lMxQB/', img:'../../../assets/noticias/noticia48.webp', titulo: 'Inauguramos nueva marquesina con nuestro logo.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DBbbAftoF0w/', img:'../../../assets/noticias/noticia47.webp', titulo: 'Si sos afiliado de SANCORSALUD podes atenderte con nosotros.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DBWk0csCeWS/', img:'../../../assets/noticias/noticia46.webp', titulo: 'Día Mundial de la Prevención contra la Osteoporosis.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DBWMvW6PBPB/', img:'../../../assets/noticias/noticia45.webp', titulo: '¡Feliz Día de la Madre!'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DBTu36jhFVi/', img:'../../../assets/noticias/noticia44.webp', titulo: 'Día Mundial de la Lucha contra el Cáncer de Mama.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DBMHIZjP0zI/', img:'../../../assets/noticias/noticia43.webp', titulo: 'Si sos afiliado de PREVENCIÓN SALUD podes atenderte con nosotros.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DBBrFdwN6XI/', img:'../../../assets/noticias/noticia42.webp', titulo: 'Hoy celebramos la riqueza de nuestras raíces y la diversidad que nos une.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DA9gBG1My_j/', img:'../../../assets/noticias/noticia41.webp', titulo: '11 y 12 de Octubre el laboratorio permanecerá cerrado por el feriado.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DA84qIAt-W1/', img:'../../../assets/noticias/noticia40.webp', titulo: 'Validez de orden medica.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DA3nJkQpQPa/', img:'../../../assets/noticias/noticia39.webp', titulo: 'Si sos afiliado de FEDERADA SALUD podes atenderte con nosotros.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DAtiV0bi5aZ/', img:'../../../assets/noticias/noticia38.webp', titulo: 'Sacar tu turno con nosotros es muy fácil.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DAoAxyAox3M/', img:'../../../assets/noticias/noticia37.webp', titulo: 'Si sos afiliado de O.S.M.A.T.A podes atenderte con nosotros.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DAWMWSIi7Zr/', img:'../../../assets/noticias/noticia36.webp', titulo: '¿Sabias que podes descargar tus resultados desde nuestra página web?'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DAMEFWLRFzm/', img:'../../../assets/noticias/noticia35.webp', titulo: 'Día de la Primavera.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DALhs4BJT5m/', img:'../../../assets/noticias/noticia34.webp', titulo: 'Día Mundial de la Sanidad.'},
    {link:'https://www.instagram.com/reel/DAEjcaxq8Pc/', img: '../../../assets/noticias/noticia33.webp', titulo: 'Estos son nuestros horarios de atención.' },
    {link:'https://www.instagram.com/reel/C_8BjDdBfk7/', img:'../../../assets/noticias/noticia32.webp', titulo: 'Día Mundial del Linfoma.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C_xxwQ_ocVf/', img:'../../../assets/noticias/noticia31.webp', titulo: '¡Saca tu turno fácil y rápido por WhatsApp!'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C_qUj5QIfBf/', img:'../../../assets/noticias/noticia30.webp', titulo: '8 de septiembre día mundial de la fibrosis quística.'},
    {link:'https://fb.watch/uraVWlBWmq/', img:'../../../assets/noticias/noticia29.webp', titulo: 'Sabemos que para sacar tu registro de conducir te piden un certificado del grupo y el factor Rh, nosotros te lo hacemos y tenes el resultado en el ACTO.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C_QW3i1CO7L/', img:'../../../assets/noticias/noticia28.webp', titulo: 'Si sos afiliado de UNION PERSONAL podes atenderte con nosotros.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C_BXdCdvgJv/', img:'../../../assets/noticias/noticia27.webp', titulo: '¿Aun no te hiciste tus chequeos anuales?'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C-2psJPvtYq/', img:'../../../assets/noticias/noticia26.webp', titulo: 'Día Nacional de Lucha contra el Síndrome Urémico Hemolítico.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C-z3zdHKEz6/', img:'../../../assets/noticias/noticia22.png', titulo: 'Feliz día de las infancias.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C-xUyM2tylw/', img:'../../../assets/noticias/noticia25.webp', titulo: 'Paso a la inmortalidad del general José de San Martín.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C-nqCilolz6/', img:'../../../assets/noticias/noticia24.webp', titulo: 'Si sos afiliado de SWISS MEDICAL podes atenderte con nosotros.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C-azvjVNk85/', img:'../../../assets/noticias/noticia23.webp', titulo: 'Te dejamos algunos tips para que el momento de la extracción sea más comodo y fácil para vos.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C-GAY51Bgav/', img:'../../../assets/noticias/noticia22.webp', titulo: 'Contamos con un servicio exclusivo donde hacemos extracciones en la comodidad de tu hogar.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C9-jNW5hvyz/', img:'../../../assets/noticias/noticia21.webp', titulo: 'Día Mundial Contra La Hepatitis.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C92V4G8udOy/', img:'../../../assets/noticias/noticia20.webp', titulo: 'Preparación para prueba PSA.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C9iMDe_PCME/', img:'../../../assets/noticias/noticia19.webp', titulo: 'Si sos afiliado de OSDE podes atenderte con nosotros.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C9SLt_IoxHO/', img:'../../../assets/noticias/noticia18.webp', titulo: 'Contamos con nuestro garage exclusivo donde hacemos extracciones en tu vehículo.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C9MtwR0hyJ7/', img:'../../../assets/noticias/noticia17.webp', titulo: 'Día de la Independencia Argentina.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C9KX-wst970/', img:'../../../assets/noticias/noticia16.webp', titulo: 'El 9 de Julio el laboratorio permanecerá cerrado por el feriado conmerorativo al día de la Independencia Argentina.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C89lhqKpeBm/', img:'../../../assets/noticias/noticia15.webp', titulo: 'Si sos afiliado de PAMI/IOMA podes atenderte con nosotros.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C8r99GlPjoy/', img:'../../../assets/noticias/noticia14.webp', titulo: 'El día 4 de Junio, reacreditamos el laboratorio.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/C8cDtOUBvnn/', img:'../../../assets/noticias/noticia13.webp', titulo: 'Día de la Bandera Argentina.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C8YKQKmB22H/', img:'../../../assets/noticias/noticia12.webp', titulo: 'En Laboratorio Romano podes abonar con cualquiera de estos Medios de Pago.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/C8XA_NxIfQI/', img:'../../../assets/noticias/noticia11.webp'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/C8UYv29h6jN/', img:'../../../assets/noticias/noticia10.webp', titulo: 'Día de la Libertad Latinoamericana.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C8R84lRC0hX/', img:'../../../assets/noticias/noticias09.webp', titulo: 'Feliz día del padre.'},

];



  constructor() { }

  ngOnInit() {
  }

}
